var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row bg-white" },
    [
      _c("div", { staticClass: "col-12" }, [
        _c(
          "div",
          { staticClass: "row" },
          [
            _c(
              "c-card",
              {
                staticClass: "cardClassDetailForm dashborad",
                attrs: { noHeader: true },
              },
              [
                _c("template", { slot: "card-detail" }, [
                  _c(
                    "div",
                    { staticClass: "col-6" },
                    [
                      _c("apexchart", {
                        ref: "yearOcuurChart",
                        attrs: {
                          height: "350",
                          type: "bar",
                          width: _vm.yearOcuurChart.chartWidth,
                          options: _vm.yearOcuurChart.chartOptions,
                          series: _vm.yearOcuurChart.series,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-6" },
                    [
                      _c("apexchart", {
                        ref: "hourOcuurChart",
                        attrs: {
                          height: "350",
                          type: "line",
                          width: _vm.hourOcuurChart.chartWidth,
                          options: _vm.hourOcuurChart.chartOptions,
                          series: _vm.hourOcuurChart.series,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }