<template>
  <div class="row bg-white">
    <div class="col-12">
      <div class="row">
        <c-card :noHeader="true" class="cardClassDetailForm dashborad">
          <template slot="card-detail">
            <div class="col-6">
              <apexchart 
                ref="yearOcuurChart" 
                height="350" 
                type="bar"
                :width="yearOcuurChart.chartWidth"
                :options="yearOcuurChart.chartOptions" 
                :series="yearOcuurChart.series"></apexchart>
            </div>
            <div class="col-6">
              <apexchart 
                ref="hourOcuurChart" 
                height="350" 
                type="line"
                :width="hourOcuurChart.chartWidth"
                :options="hourOcuurChart.chartOptions" 
                :series="hourOcuurChart.series"></apexchart>
            </div>
          </template>
        </c-card>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import selectConfig from '@/js/selectConfig';
import VueApexCharts from "vue-apexcharts";
export default {
  name: 'first-line-status',
  components: {
    apexchart: VueApexCharts
  },
  props: {
    param: {
      type: Object,
      default: () => ({
        plantCd: '',
      }),
    },
    count: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      fullscreen: false,
      searchParam: {
        plantCd: '',
        processCd: '',
      },
      yearOcuurChart: {
        chartOptions: {
          title: {
            text: '사업장별 안전사고 발생 건수'
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '60%',
              endingShape: 'rounded'
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
          },
          xaxis: {
            categories: ['2017', '2018', '2019', '2020', '2021'],
          },
          yaxis: {
            title: {
              text: '건'
            }
          },
          fill: {
            opacity: 1
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return  + val + " 건"
              }
            }
          }
        },
        series: [
          {
            name: '1공장',
            data: [2, 6, 6, 6, 3]
          },
          {
            name: '2공장',
            data: [5, 5, 8, 1, 2]
          },
          {
            name: '3공장',
            data: [1, 1, 1, 1, 2]
          },
          {
            name: '4공장',
            data: [2, 4, 6, 1, 5]
          },
          // {
          //   name: 'TOTAL',
          //   data: [10, 16, 21, 10, 12]
          // },
        ],
        chartWidth: '80%',
      },
      hourOcuurChart: {
        chartOptions: {
          title: {
            text: '시간대별 발생 건수'
          },
          chart: {
            height: 350,
            type: 'line',
            zoom: {
              enabled: false
            },
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            width: [5, 7, 5],
            curve: 'straight',
            dashArray: [0, 8, 5]
          },
          xaxis: {
            categories: ['8시','9시','10시','11시','12시','13시','14시','15시','16시','17시','18시','18시 이후'],
          },
          yaxis: {
            title: {
              text: '건'
            }
          },
          fill: {
            opacity: 1
          },
          grid: {
            borderColor: '#f1f1f1',
          },
          markers: {
            size: 0,
            hover: {
              sizeOffset: 6
            }
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return   val + " 건"
              }
            }
          }
        },
        series: [
          {
            name: '1공장',
            data: [2, 6, 6, 6, 3, 1, 2, 3, 4, 5, 1, 3]
          },
          {
            name: '2공장',
            data: [5, 5, 8, 1, 2, 2, 6, 6, 6, 3, 5, 8]
          },
          {
            name: '3공장',
            data: [1, 1, 1, 1, 2, 1, 0, 2, 3, 4, 5, 1]
          },
          {
            name: '4공장',
            data: [2, 4, 6, 1, 5, 3, 4, 5, 6, 7, 1, 2]
          },
        ],
        chartWidth: '80%',
      },
      editable: true,
      searchUrl: '',
      popupOptions: {
        isFull: false,
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
      listUrl: '',
    };
  },
  watch: {
    'count'() {
      this.getDetail();
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
    // window.addEventListener('resize', this.setSize);
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
    // window.removeEventListener('resize', this.setSize);
  },
  methods: {
    init() {
      
      // this.listYearUrl = selectConfig.sop.iim.accident.status.year.url;
      // this.listHourUrl = selectConfig.sop.iim.accident.status.hour.url;

      // code setting
      this.getYearList();
      this.getHourList();
    },
    getYearList() {
      this.$http.url = this.listYearUrl;
      this.$http.type = 'GET';
      this.$http.param = this.param;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    getHourList() {
      this.$http.url = this.listHourUrl;
      this.$http.type = 'GET';
      this.$http.param = this.param;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    }
  }
};
</script>
<style lang="sass">
.el-cascader-menu__wrap
  height: calc(var(--cascader-height-var)) !important
.dashborad
  .customCardbody
    padding: 0px !important
    margin: 0px !important
.grid-menu [class*=col-]
  border-width: 0 !important
  padding: 0px !important
.widget-yearOcuurChart
  padding: 5px !important
.widget-yearOcuurChart .widget-numbers
  margin: 12px !important

.none-bottom
  .q-field--with-bottom
    padding-bottom: 0px !important

.app-main__inner .riskassess-dashboard
  .row [class*=col-]
    padding: 0px !important
</style>
<style scoped>
.blinking {
  -webkit-animation:blink 1s ease-in-out infinite alternate;
  -moz-animation:blink 1s ease-in-out infinite alternate;
  animation:blink 1s ease-in-out infinite alternate;
}
@-webkit-keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
@-moz-keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
@keyframes blink {
  0% { opacity:0 }
  100% { opacity:1 }
}
</style>